// @ts-nocheck

require('./bootstrap');

/*
|--------------------------------------------------------------------------
| VUE Import and JS Plugin Setup
|--------------------------------------------------------------------------
*/
import Vue from 'vue';
window.Vue = Vue;

import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

// Vue Resource $http
import VueResource from 'vue-resource';
Vue.use(VueResource);

import ToggleButton from 'vue-js-toggle-button';
Vue.use(ToggleButton);

Vue.set(Vue.prototype, '_', _);

// Prototypes
require('./globals/prototypes.js');

// Moment JS
import moment from 'moment'
Vue.set(Vue.prototype, 'moment', moment);

import VUE from './globals/vue-globals.js';
Vue.set(Vue.prototype, 'VUE', VUE);

// Custom Lodash Functions
require('./lodash-mixins.js');

// Tinymce Setup
require('tinymce/tinymce');
require('tinymce/themes/modern/theme');

// jQuery Validate
require('jquery-validation/dist/jquery.validate.js');

// Spectrum Colorpicker
import 'spectrum-colorpicker/spectrum.css';
require('spectrum-colorpicker/spectrum.js');

// Fullcalendar
import 'fullcalendar/dist/fullcalendar.css';
import 'fullcalendar-scheduler/dist/scheduler.css';
require('fullcalendar-scheduler');

import 'croppie/croppie.css';
require('croppie/croppie.js');

import VueQRCodeComponent from 'vue-qrcode-component';
Vue.component('qr-code', VueQRCodeComponent);
/*
|--------------------------------------------------------------------------
| Utility Package Components
|--------------------------------------------------------------------------
|
| These are the helper components maybe we can call it plugins that are
| downloaded externally
|
*/
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
Vue.component('datepicker', require('vue-bootstrap-datetimepicker'));

// https://vue-multiselect.js.org
import 'vue-multiselect/dist/vue-multiselect.min.css';
Vue.component('multiselect', require('vue-multiselect').default);

// Vue Select
import 'vue-select/dist/vue-select.css';
Vue.component('v-select', require('vue-select').default);

import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.css';
require('bootstrap-datepicker');

import draggable from 'vuedraggable';
Vue.component('draggable', draggable);

Vue.component('login-panel', require('./components/Auth/LoginPanel.vue').default);
Vue.component('login-form', require('./components/Auth/LoginForm.vue').default);
Vue.component('forgot-password-form', require('./components/Auth/ForgotPasswordForm.vue').default);
Vue.component('reset-password-form', require('./components/Auth/ResetPasswordForm.vue').default);



/*
|--------------------------------------------------------------------------
| App Initialization
|--------------------------------------------------------------------------
*/
Vue.http.interceptors.push((request, next) => {
    request.headers.set('X-CSRF-TOKEN', document.querySelector('#csrf-token').getAttribute('content'));
    next();
});

/**
 * APP INITIALIZATION
 */
const bus = new Vue();
global.bus = bus;
const app = new Vue({
    el: '#smartpadpro',

    data: {
    },

    mounted()
    {
    },

    methods: {
    }
});
