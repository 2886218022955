
import CONSTANTS from '@/globals/constants.js';

let GLOBALS = {
	constants: CONSTANTS,

	findParent(parent, field, value)
	{
		while(!parent[field] && parent[field] != value)
		{
			parent = parent.$parent;
		}
		
		return parent;
	},

	products: {
		optionHidden(option, id) {
            var hidden = option.hide_on ? JSON.parse(option.hide_on) : false;
            return hidden ? _.find(hidden, { id: id }) : false;
		},

		optionHasValue(option, withAmount) {
			if(option.select_id && option.select_label) {
				return true;
			}

			else if(!option.select_id && option.name) {
				if(!option.item) {
					if(option.type != 'text_only' && option.type != 'text' && option.amount) {
						return true;
					}
					else if(option.type == 'text_only' && option.select_label) {
						return true;
					}
					else if(option.type == 'text' && option.notes) {
						return true;
					}
					else if(option.type == 'product_notes' && option.select_label) {
						return true;
					}
				}
				else if(option.item) {
					return true;
				}
			}

			else if(!option.select_id && !option.name && option.notes) {
				return true;
			}
		}
	},

	isObject(object)
	{
		return typeof(object) == 'object' && !Array.isArray(object)
	},

	storage: {
		get(key, defaultData)
		{
			var data = localStorage.getItem(key);

			if(!data || data == 'undefined')
			{
				data = JSON.stringify(defaultData);
				localStorage.setItem(key, data);
			}

			return JSON.parse(data);
		},

		save(key, data)
		{
			localStorage.setItem(key, JSON.stringify(data));
		},

		destroy(key)
		{
			localStorage.removeItem(key);
		}
	},

	getMeta(name)
	{
		var meta = document.querySelector(`[name=${name}]`);
		return meta ? meta.getAttribute('content') : 0;
	},

	isServerError(response)
	{
		if(response.status == 500 && response.bodyText.match('Server Error'))
		{
			return true;
		}

		return false;
	},

    /**
     * Returns the Second to the Last Object and the key of the Last Object
     */
    crawlObject(object, model)
    {
        var keys  = model.split('.');
        var model = object;
        var data = {};

        _.forEach(keys, function(key, index)
        {
            // If not last
            if(index != keys.length - 1)
            {
                model = model[key];
            }
            else
            {
                data.model = model;
                data.key = key;
            }
        });

        return data;
    },

	dateFormat()
	{
		return format = $('meta[name=date_format]').attr('content');
	},

	alert(title, content, type)
	{
		var classes = {
			success: 'success',
			error: 'danger',
			warning: 'warning',
			info: 'info',
			question: 'default'
		};

		var colorClass = classes[type];

		const swalWithBootstrapButtons = swal.mixin({
			confirmButtonClass: 'btn btn-'+colorClass+'',
			buttonsStyling: false,
		});
		swalWithBootstrapButtons({
            title: '<span class="text-'+colorClass+'">'+title+'</span>',
            html: '<span class="text-'+colorClass+'">'+content+'</span>',
            type: type
        });
	},

	confirm(title, content, type, confirmCallback, cancelCallback, buttonLabels, input, dontAllowOutsideClick)
	{
		var classes = {
			success: 'success',
			error: 'danger',
			warning: 'warning',
			info: 'info',
			question: 'default'
		};

		var colorClass = classes[type];

		const swalWithBootstrapButtons = swal.mixin({
			confirmButtonClass: 'btn btn-'+colorClass,
			cancelButtonClass: 'mleft-10 btn btn-default',
			buttonsStyling: false,
		});

		swalWithBootstrapButtons($.extend({
            title: '<span class="text-'+colorClass+'">'+title+'</span>',
            html: '<span class="text-'+colorClass+'">'+content+'</span>',
			type: type,
			allowOutsideClick: !dontAllowOutsideClick,
			confirmButtonText: !buttonLabels ? 'Yes' : buttonLabels.confirm,
			cancelButtonText: !buttonLabels ? 'No' : buttonLabels.cancel,
			showConfirmButton: !buttonLabels ? 'Yes' : buttonLabels.showConfirm,
			showCancelButton: !buttonLabels ? 'Yes' : buttonLabels.showCancel,
		}, (input ? input : {}))).then((result) => {
			if(confirmCallback && !result.dismiss) {
				confirmCallback(result.value);
			}
			else if(cancelCallback && result.dismiss == 'cancel'){
				cancelCallback(result.value);
			}
		});
	},

    customConfirm(data, confirmCallback, cancelCallback, input)
    {
        const swalWithBootstrapButtons = swal.mixin({
            confirmButtonClass: 'btn btn-'+data.confirmButton.class,
            cancelButtonClass: 'mleft-10 btn btn-'+data.cancelButton.class,
            buttonsStyling: false,
        });

        swalWithBootstrapButtons($.extend({
            title: '<span class="text-'+data.textClass+'">'+data.title+'</span>',
            html: '<span class="text-'+data.textClass+'">'+data.content+'</span>',
            type: data.type,
            showCancelButton: data.cancelButton ? true : false,
            confirmButtonText: !data.confirmButton.label ? 'Yes' : data.confirmButton.label,
            cancelButtonText: !data.cancelButton.label ? 'No' : data.cancelButton.label,
        }, (input ? input : {}))).then((result) => {
            if(confirmCallback && !result.dismiss) {
                confirmCallback(result.value);
            }
            else if(cancelCallback && result.dismiss == 'cancel'){
                cancelCallback(result.value);
            }
        });
    },

    confirmWithInput(title, content, type, confirmCallback, cancelCallback, buttonLabels, input, dontAllowOutsideClick)
    {
		var classes = {
			success: 'success',
			error: 'danger',
			warning: 'warning',
			info: 'info',
			question: 'default'
		};

		var colorClass = classes[type];

		const swalWithBootstrapButtons = swal.mixin({
			confirmButtonClass: 'btn btn-'+colorClass,
			cancelButtonClass: 'mleft-10 btn btn-default',
			buttonsStyling: false,
		});

		swalWithBootstrapButtons($.extend({
            title: '<span class="text-'+colorClass+'">'+title+'</span>',
            html: '<span class="text-'+colorClass+'">'+content+'</span>',
			type: type,
			input: 'text',
			allowOutsideClick: !dontAllowOutsideClick,
			confirmButtonText: !buttonLabels ? 'Yes' : buttonLabels.confirm,
			cancelButtonText: !buttonLabels ? 'No' : buttonLabels.cancel,
			showConfirmButton: !buttonLabels ? 'Yes' : buttonLabels.showConfirm,
			showCancelButton: !buttonLabels ? 'Yes' : buttonLabels.showCancel,
		}, (input ? input : {}))).then((result) => {
			if(confirmCallback && !result.dismiss) {
				confirmCallback(result.value);
			}
			else if(cancelCallback && result.dismiss == 'cancel'){
				cancelCallback(result.value);
			}
		});
    },

	location: {
		calculateDistance(lat1, lon1, lat2, lon2)
		{
			/** Converts numeric degrees to radians */
			function toRad(Value) {
			    return Value * Math.PI / 180;
			}

			// Radius of the earth in:  1.609344 miles,  6371 km  | var R = (6371 / 1.609344);
			var R = 3958.7558657440545; // Radius of earth in Miles
			var dLat = toRad(lat2-lat1);
			var dLon = toRad(lon2-lon1);
			var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
			        Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
			        Math.sin(dLon/2) * Math.sin(dLon/2);

			var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
			var d = R * c;

			return d;
		}
	},

	bootstrap: {
    	popover: {
			confirmDelete(el, title, confirm, cancel)
			{
				el.attr('data-popover', true);
				var uuid = _.uuid();

				el.popover({
					trigger: 'manual',
					title: title,
					content: `
						<button id="confirm-${uuid}" class="popover-confirm-button text-size-12 btn btn-sm btn-danger">Delete</button>
						<button id="cancel-${uuid}" class="popover-cancel-button text-size-12 btn btn-sm btn-default">Cancel</button>
					`,
					placement: 'bottom',
					animation: true,
					html: true
				});
				el.popover('show');

				$(document).off('click', `#confirm-${uuid}`);
				$(document).on('click', `#confirm-${uuid}`, function()
				{
					el.popover('dispose');
					el.popover('hide');
					if(confirm) { confirm(); }
				}.bind(this));

				$(document).off('click', `#cancel-${uuid}`);
				$(document).on('click', `#cancel-${uuid}`, function()
				{
					el.popover('dispose');
					el.popover('hide');
					if(cancel) { cancel(); }
				});
			}
		},

		loadPlugins()
		{
			this.reloadPopover();
		},

		popoverHover(element, options)
		{
			var show;

            return element.popover(options)
            .on('mouseenter', function () {
                var _this = this;
                $(_this).popover('hide');

                show = setTimeout(function () {
	                $(this).popover('show');
	                $('.popover').on('mouseleave', function () {
	                    $(_this).popover('hide');
	                });
                }.bind(this), 1000);
            }).on('mouseleave', function () {
            	clearTimeout(show);

                var _this = this;
                setTimeout(function () {
                    if (!$('.popover:hover').length) {
                        $(_this).popover('hide');
                    }
                }, 100);
            });
		},

		reloadPopover()
		{
			$("[data-toggle=popover]").popover();
		},

		modal: {
			toggle(element, action)
			{
                element.modal(action);
                element.find('label.error').remove();
                element.find('.error').removeClass('error');

                // If inside the modal fix the scroll by appending the model-open class to the body again.
                if(action == 'hide')
                {
					element.find('.modal').modal('hide');

                    setTimeout(function()
                    {
		                $.each($('.modal'), function()
		                {
		                	if($(this).css('display') == 'block')
		                	{
                        		$('body').addClass('modal-open');
		                	}
		                });
                    }, 1500);
                }
			}
		},
	},

	validate: {
		tabStates(menu, reset)
		{
			setTimeout(function()
			{
	            $.each(menu.find('a'), function()
	            {
	                $(this).removeClass('bg-red text-white');
	                $(this).find('.error-message').remove();

	                var hasError = 0;
	                $.each($($(this).attr('href')).find('.error'), function()
	                {
	                    hasError += $(this).text() || $(this).val() ? 1 : 0;
	                });
	                
	                if(hasError && !reset)
	                {
	                    $(this).addClass('bg-red text-white');
	                    $(this).append('<div class="error-message"><em>There are invalid fields in this tab.</em></div>');
	                    $(document).scrollTop(0);
	                }
	            });
			}, 100);
		}
	},

    /**
     * Get Google Maps Direction
     */
    getDirection(destination){
        var userLocation = navigator.geolocation.getCurrentPosition(this.currentLocation);
        window.open("https://www.google.com/maps/dir/"+userLocation+"/"+destination, 'Smartpad Pro','_blank');
    },

    /**
     * Get Current Location
     */
    currentLocation(position) {
        return position.coords.latitude+','+position.coords.longitude;
    },

    toFixed( num, precision ) {
        return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
	},

	/**
	 * Get Friendly Types
	 * @param file
	 */
	getFileFriendlyType(file)
	{
		var extension = this.getFileExtension(file);
		var types = CONSTANTS.fileTypes;
		if (Object.keys(types).includes(extension)) {
			return types[extension];
		}
		return null;
   },

  	getFileExtension(file)
  	{
  		// always get the last index of the file name
  		var extension = file.name.split('.').pop();
  		// remove trailing whitespace if there's a space eg. Image.jpg (another copy)
  		var whitespace = this.hasWhiteSpace(extension);
  		if (whitespace) {
  			var index = extension.indexOf(' ');
    		var textModified = extension.substring(0, index).trim();
  			return textModified ? textModified : '';
  		}

  		return extension ? extension.toLowerCase() : '';

  	},

  	hasWhiteSpace(s) {
		return /\s/g.test(s);
	},
};
export default GLOBALS;