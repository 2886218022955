Array.prototype.first = function() {
    return this[0];
}

Array.prototype.last = function() {
    return this[this.length - 1];
}

Number.prototype.money = function(noRound)
{
	if(this) {
        if(!noRound) {
            var number = this.toFixed(2);
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        else {
            fixed = 2;
            fixed = Math.pow(10, fixed);
            return Math.floor(this * fixed) / fixed;
        }
	}

	return '0.00';
}

String.prototype.ucfirst = function() {
	return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.splitRemoveJoin = function(delimeter, index) {
    var value = this.split(delimeter);
    value.splice(index, 1);
    return value.join(delimeter);
}

String.prototype.money = function()
{
	if(this) {
		var number = parseFloat(this);
		return number.money();
	}

	return '0.00';
}

String.prototype.replaceAll = function(a, b) {
    return this.replace(new RegExp(a.replace(/([.?*+^$[\]\\(){}|-])/ig, "\\$1"), 'ig'), b)
}

String.prototype.replaceAt=function(index, replacement) {
    return this.substr(0, index) + replacement+ this.substr(index + replacement.length);
}

String.prototype.replaceIfStatementClosingParenthesis = function(value)
{
	var string = this;
    var active = false;
    var child  = 0;
    for(var i in string)
    {
        if(string[i] == '(') {
            if(active) {
                child++;
            }
            if(string[i-1] == 'f' && string[i-2] == 'i') {
            	active = true;
            }
            else {}
        }

        if(string[i] == ')' && active) {
            if(child > 0) {
                child--;
            }
            else {
                string = string.replaceAt(parseInt(i), '~');
                child = 0;
                active = false;
            }
        }
    }

    return string.replace(/~/g, value);
}

String.prototype.replaceStringSpacesInsideQuotes = function(value)
{
	var string  = this;
    var active  = false;
    var indexes = [];
    for(var i in string)
    {
       	if(string[i] == '"') {
       		active = !active;
       	}

       	if(active && string[i] == ' ') {
            string = string.replaceAt(parseInt(i), '`');
       	}
    }
    
    return string.replace(/`/g, value);
}
