<template>
    <div class="card login-card-v2" style="width: 500px; margin: 0 auto; z-index: 1">
        <div class="card-body">
           <div class="login-logo-holder">
                <img src="/images/quoterite-logo.svg" style="width:190px; height:auto;" alt="logo">
            </div>
            <div v-if="type == 'login'">
                <div v-if="view == 'login'">
                    <login-form></login-form>
                </div>
                <div v-else-if="view == 'forgot-password'">
                    <forgot-password-form></forgot-password-form>
                </div>
            </div>
            <div v-else-if="type === 'authenticate'">
                <authenticate-form></authenticate-form>
            </div>
            <div v-else>
                <reset-password-form></reset-password-form>
            </div>
        </div>
    </div>
</template>
<style>

.blinking{
    animation:blinkingText 1.2s infinite;
}
@keyframes blinkingText{
    0%{     color: #FF0000;    }
    49%{    color: #000; }
    60%{    color: transparent; }
    99%{    color:transparent;  }
    100%{   color: #000;    }
}
</style>
<script>
    import { required } from 'vuelidate/lib/validators';

    export default 
    {
        props: {
            type: '',
            token: ''
        },

        data()
        {
            return {
                view: 'login'
            };
        },

        validations:
        {
            form: {
                username: { required },
                password: { required }
            }
        },

        mounted()
        {
            $(window).resize(function()
            {
                this.adjustLoginPanel();
            }.bind(this));

            setTimeout(function()
            {
                this.adjustLoginPanel();
            }.bind(this), 0);
        },

        methods: 
        {
            /**
             * Adjusts the Top Login Panel
             */
            adjustLoginPanel()
            {
                $.each($('.card'), function()
                {
                    var height = $(this).height();
                    var windowHeight = $(window).height();

                    // $(this).css({ marginTop: ((windowHeight - height) / 2)+'px' });
                });
            },

            /**
             * Updates the View to LOGIN or FORGOT PASSWORD
             */
            changeView(view)
            {
                this.view = view;
            }
        }
    }
</script>