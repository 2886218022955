import GLOBALS from "@/globals/globals";

let VUE = {

    /**
     * Updates Parent's Model from Child Compnent
     */
    updateParentModel: function(object, model, value)
    {
        var parent = !parent ? object.$parent : object.$parent.$parent;

        //Override if there's a set specific parent
        if(vueParent){
            parent = vueParent;
        }

        var data = GLOBALS.crawlObject(parent, model);
        if(data.model)
        {
            data.model[data.key] = value;
        }
    },

	address: {
		/**
		 * Used in Quotes Customer Information & Contacts Create/Update
		 * @param  component
		 */
		copyHomeToBilling: function(component, copyFromAddress)
		{
            var copyFromAddress = !copyFromAddress ? component.form.addresses.home : copyFromAddress;

            _.extend(component.form.addresses.billing, copyFromAddress);

            if(copyFromAddress.lat && copyFromAddress.lng)
            {
                component.$refs.map[1].setCenter({
                    lat: copyFromAddress.lat,
                    lng: copyFromAddress.lng
                });
            }
		}
	},

    contact: {
        setContact(object, contact, options, type)
        {
            object.selectedContact = contact;

            if(type != 'loaded')
            {
                // Set contact fields
                if(contact && contact.addresses)
                {
                    object.form.addresses.home    = contact.addresses[0] ? contact.addresses[0] : {};
                    object.form.addresses.billing = contact.addresses[1] ? contact.addresses[1] : {};
                    object.same_as_home_address   = contact.addresses[0] && contact.addresses[1] ? (contact.addresses[0].full_address == contact.addresses[1].full_address ? true : false) : false;
                    
                    object.form.mobile            = contact.contacts.length > 0 ? _.find(contact.contacts, { type: 'mobile' }) : '';
                    object.form.mobile            = object.form.mobile ? object.form.mobile.number : '';

                    object.form.phone             = contact.contacts.length > 0 ? _.find(contact.contacts, { type: 'phone' }) : '';
                    object.form.phone             = object.form.phone ? object.form.phone.number : '';

                    object.form.billing_mobile    = contact.contacts.length > 0 ? _.find(contact.contacts, { type: 'billing_mobile' }) : '';
                    object.form.billing_mobile    = object.form.billing_mobile ? object.form.billing_mobile.number : '';

                    object.form.billing_phone     = contact.contacts.length > 0 ? _.find(contact.contacts, { type: 'billing_phone' }) : '';
                    object.form.billing_phone     = object.form.billing_phone ? object.form.billing_phone.number : '';

                    object.form.first_name        = contact.first_name;
                    object.form.last_name         = contact.last_name;
                    object.form.company_name      = contact.company_name;
                    object.form.email             = contact.email;
                    object.form.billing_email     = contact.billing_email;

                    if(object.$refs['map'] && object.$refs['map'][0]) {
                        object.$refs['map'][0].setCenter(object.form.addresses.home.lat ? { lat: object.form.addresses.home.lat, lng: object.form.addresses.home.lng } : false);
                    }
                    if(object.$refs['map'] && object.$refs['map'][1]) {
                        object.$refs['map'][1].setCenter(object.form.addresses.home.lat ? { lat: object.form.addresses.billing.lat, lng: object.form.addresses.billing.lng } : false);
                    }
                }
                // Reset fields
                else
                {
                    object.form.addresses.home    = { address: '' };
                    object.form.addresses.billing = { address: '' };
                    object.same_as_home_address   = true;
                    _.forEach(['mobile', 'phone', 'first_name', 'last_name', 'email'], function(field)
                    {
                        object.form[field] = '';
                    });
                }
            }
        }
    }

};
export default VUE;