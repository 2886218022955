_.mixin({
    uuid()
    {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c)
        {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    },

    randomString(len) {
        var p = "abcdefghijklmnopqrstuvwxyz";
        return [...Array(len)].reduce(a=>a+p[~~(Math.random()*p.length)],'');
    },

    multiselectData(arr, valueField, nameField, textColor, backgroundColor)
    {
        
        var result = [];
        _.forEach(arr, (data) => {
            result.push({
                value: data[valueField ? valueField : 'id'],
                name: data[nameField ? nameField : 'name'],
                tag_color: data.text_color ? data.text_color : 'white',
                tag_background: data.background_color ? data.background_color : '#0db7dd',
            });
        });

        return result;
    },

    parseFloat(number)
    {
        var number = parseFloat(number);
        return !isNaN(number) ? number : 0;
    },

    parseInt(number)
    {
        var number = parseInt(number);
        return !isNaN(number) ? number : 0;
    },

    dateOverlaps(dates)
    {
        if(dates[0][0] <= dates[1][0] && dates[0][1] > dates[1][0] ||
           dates[0][0] >= dates[1][0] && dates[0][1] <= dates[1][1] ||
           dates[0][0] <= dates[1][1] && dates[0][1] >= dates[1][1]) {
            return true;
        }

        return false;
    },

    convertObjectToSortedArray: function(object)
    {
        var data = [];

        var keys = _.sortBy(Object.keys(object));

        _.forEach(keys, function(val, key)
        {
            data.push({
                value: val,
                label: object[val]
            });
        })

        return data;
    },

    objectPluckConvertToKey(object, key, defaultValue)
    {
        var data = {};

        _.forEach(object, function(val)
        {
            data[val[key]] = defaultValue;
        });

        return data;
    },

    extendExisting(object1, object2, convertNullToEmpty)
    {
        _.forEach(object2, function(val, key)
        {
            if(_.has(object1, key))
            {
                object1[key] = object2[key] ? object2[key] : (convertNullToEmpty ? '' : object2[key]);
            }
        })
    },

    extendMap(array, object)
    {
        array.forEach(data => {
            _.extend(data, object);
        });
    },

    extendIfNotNull(object1, object2)
    {
        _.forEach(object2, function(val, key)
        {
            if(val)
            {
                object1[key] = object2[key];
            }
        })
    },

    objectToParams(obj)
    {
        return '?' + $.param(obj);
    },

    includesInsensitive(array, value)
    {
        exists = false;
        _.forEach(array, val => {
            console.log(val, value);
            if(value == val) {
                exists = true;
            }
        });

        return exists;
    },

    findInsensitive(object, where)
    {
        var result;
        var conditionResult;

        object.forEach(data =>
        {
            conditionResult = true;

            _.forEach(where, (field, key) => {
                if(data[key] != field)
                {
                    conditionResult = false;
                }
            });

            if(conditionResult)
            {
                result = data;
            }
        });

        return result;
    },

    findKeyInsensitive(object, where)
    {
        var result;
        var conditionResult;

        object.forEach((data, mainKey) =>
        {
            conditionResult = true;

            _.forEach(where, (field, key) => {
                if(data[key] != field)
                {
                    conditionResult = false;
                }
            });

            if(conditionResult)
            {
                result = mainKey;
            }
        });

        return result;
    },

    sumInsensitive(arr, column)
    {
        var result = 0;

        _.forEach(arr, data =>
        {
            result += parseFloat((data[column] && !isNaN(data[column])) ? data[column] : 0);
        });

        return result;
    },

    ucwords(str) {
        return str.replace(/^(.)|\s+(.)/g, function ($1) {
            return $1.toUpperCase()
        });
    },

    arrayRemoveDuplicateAndCount(arr, key)
    {
        const unique = arr
        .map(e => e[key])

        // store the keys of the unique objects
        .map((e, i, final) => final.indexOf(e) === i && i)

        // eliminate the dead keys & store unique objects
        .filter(e => arr[e]).map(e => {
            arr[e].qty = 0;
            // count instances of array
            arr.forEach((a) => {
                (a[key] == arr[e][key]) ? arr[e].qty++ : null
            });
            return arr[e];
        });

        return unique;
    },

    sumByInsensitive(arr, type, disableQuantity, applyDiscount, discountMarkup)
    {
        var value, quantity;
        var total = 0;

        if(arr && arr[0]) {
            arr.forEach(obj =>
            {
                value = (obj[type] ? (obj[type] * 1) : 0);
                if(!disableQuantity) {
                    quantity = obj.quantity ? (obj.quantity * 1) : 1;
                    value = value * quantity;
                }

                total += !applyDiscount
                    ? value
                    : (obj[applyDiscount] 
                        ? (discountMarkup == 'decrease' 
                            ? (value - (value * (obj[applyDiscount] / 100))) 
                            : (value + (value * (obj[applyDiscount] / 100)))
                          )
                        : value
                      );
            });
        }

        return total.toFixed(2);
    },

    arrayAddOrRemove(arr, value, field)
    {
        if(!field)
        {
            if(_.includes(arr, value)) {
                _.remove(arr, val => (val == value));
            }
            else {
                arr.push(value);
            }
        }
        
        return arr;
    },

    ellipsis(str, n)
    {
        return str && (str.length > n) ? str.slice(0, n-1) + '...' : str;
    }
});
