<template>
    <div>
        <div v-if="messages.error" class="alert alert-danger">{{ messages.error }}</div>
        <div v-if="messages.success" class="alert alert-success">{{ messages.success }}</div>
        <form @submit.prevent="submit" class="form-horizontal login-container">
            <div class="login-field">
                <i class="fa fa-user"></i>
                <input class="form-control" type="text" v-model="form.username" placeholder="Username">
            </div>
            <div v-if="$v.form.username.$error" class="text-danger">Username is required.</div>

            <div class="login-field mtop-15">
                <i class="fa fa-lock"></i>
                <input class="form-control" :type="!view_password ? 'password' : 'text'" v-model="form.password" placeholder="Password">
            </div> 
            <div v-if="$v.form.password.$error" class="text-danger">Password is required.</div>

            <br>
            
            <!-- <label class="checkbox" style="width: 140px; margin: 0 auto;">
                Master Console
                <input type="checkbox" v-model="form.master_console" />
                <span class="checkmark"></span>
            </label> -->
            <!-- <label class="checkbox" style="width: 130px; margin: 0 auto;">
                Remember Me
                <input type="checkbox" v-model="form.remember" />
                <span class="checkmark"></span>
            </label> -->
            <label class="checkbox" style="width: 150px; margin: 0 auto;">
                View Password
                <input type="checkbox" v-model="view_password" />
                <span class="checkmark"></span>
            </label>
            <br>
            <div class="forgot-password-wrapper text-center">
                <button class="btn btn-spp mbottom-20" :disabled="loading">
                    LOGIN
                </button>
                <br>
                <div :class="['text-center mbottom-20', loading == 1 ? '' : 'd-none']">
                    <img src="/images/blue-loader.gif">
                </div>
                <a href="javascript:void(0)" @click="$parent.changeView('forgot-password')">Forgot Password?</a>
            </div>
            <br>
        </form>
    </div>
</template>

<script>
    import { required } from 'vuelidate/lib/validators';

    export default 
    {
        data()
        {
            return {
                loading: false,
                view_password: false,
                messages: {
                    success: '',
                    error: '',
                },
                form: {
                    username: '',
                    password: '',
                    master_console: false,
                    remember: false,
                    _token: '' 
                }
            }
        },

        validations:
        {
            form: {
                username: { required },
                password: { required }
            }
        },

        mounted()
        {
            this.form._token = $('meta[name="csrf-token"]').attr('content');
        },

        methods:
        {
            submit()
            {
                this.$v.form.$touch();

                if(!this.$v.form.$invalid)
                {
                    this.$http.get('/api/auth/generate-token').then(resToken => {
                        this.form._token = resToken.body.data;
                        this.messages.success = this.messages.error = false;
                        this.loading = true;
                        this.$http.post('/api/auth', this.form).then(response => 
                        {
                            this.messages.success = 'Logged in successfully, please wait.';
                            window.location.href = response.body.mfa ? '/auth/authenticate' : '/#nav-home';
                        }, response =>
                        {
                            if (response.status == 419) {
                                this.messages.error = 'The login form has expired, please try again. In the future, reload the login page if it has been open for several hours.';
                            } else if (response.body.data == 'Trial account already expired.') {
                                this.messages.error = response.body.data;
                            } 
                            else {
                                this.messages.error = 'Invalid username or password.';
                            }
                            this.loading = false;
                        });
                    });                    
                }
            }
        }
    }
</script>