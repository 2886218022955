<template>
    <form @submit.prevent="submit" class="form-horizontal text-center">
        <div v-if="messages.error" class="alert alert-danger">{{ messages.error }}</div>
        <div v-if="messages.success" class="alert alert-success">{{ messages.success }}</div>
        <div class="login-field">
            <i class="fa fa-envelope"></i>
            <input class="form-control" type="text" v-model="form.email" placeholder="Email">
        </div>
        <div v-if="$v.form.email.$error" class="text-danger">Email is required.</div>

        <br>
        
        <div class="text-center">
            <button class="btn btn-spp mbottom-20" :disabled="loading">
                Submit
            </button>
            <br>
            <div class="text-center mbottom-20 d-none">
                <img src="/images/blue-loader.gif">
            </div>
            <a href="javascript:void(0)" @click="$parent.changeView('login')">Back to login</a>
        </div>
        <br>
    </form>
</template>

<script>
    import { required } from 'vuelidate/lib/validators';

    export default 
    {
        data()
        {
            return {
                loading: false,
                messages: {
                    success: '',
                    error: '',
                },
                form: {
                    email: '',
                }
            }
        },

        validations:
        {
            form: {
                email: { required },
            }
        },

        methods:
        {
            submit()
            {
                this.$v.form.$touch();

                if(!this.$v.form.$invalid)
                {
                    this.messages.success = this.messages.error = false;
                    this.loading = true;
                    this.$http.post('/api/auth/forgot-password', this.form).then(response => 
                    {
                        this.loading = false;
                        this.messages.success = 'We\'ve sent an instruction to your email, Please check.';
                    }, response =>
                    {
                        this.messages.error = 'Invalid email.';
                        this.loading = false;
                    });
                }
            }
        }
    }
</script>