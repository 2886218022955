<template>
    <form ref="form" @submit.prevent="submit" class="form-horizontal text-center">
        <h3 class="text-blue">Password Setup</h3>
        <br>
        <div class="login-field">
            <i class="fa fa-user"></i>
            <input class="form-control" type="password" v-model="form.password" placeholder="Password" name="password" id="password">
        </div>

        <div class="login-field mtop-15">
            <i class="fa fa-lock"></i>
            <input class="form-control" type="password" v-model="form.confirm_password" placeholder="Confirm Password" name="confirm_password">
        </div> 

        <br>
        
        <div class="text-center">
            <button class="btn btn-spp mbottom-20" :disabled="loading">
                Save your New Password
            </button>
            <br>
            <div :class="['text-center mbottom-20', loading == 1 ? '' : 'd-none']">
                <img src="/images/blue-loader.gif">
            </div>
        </div>
        <br>
    </form>
</template>

<script>
    export default 
    {
        data()
        {
            return {
                loading: false,
                messages: {
                    success: '',
                    error: '',
                },
                form: {
                    password: '',
                    confirm_password: '',
                }
            }
        },

        mounted()
        {
            this.form.token = this.$parent.token;

            $(this.$refs.form).validate({
                ignore: [],
                rules: {
                    password: { required: true, minlength: 7 },
                    confirm_password: {
                        equalTo: '#password'
                    },
                }
            });
        },

        methods:
        {
            submit()
            {
                if($(this.$refs.form).valid())
                {
                    this.messages.success = this.messages.error = false;
                    this.loading = true;

                    this.$http.post('/api/auth/reset-password', this.form).then(response => 
                    {
                        this.messages.success = 'Password successfully resetted, please wait.';
                        window.location.href = '/';
                    }, response =>
                    {
                        this.messages.error = 'An error occurred while resetting your password, Please try to reset it again.';
                        this.loading = false;
                    });    
                }
            }
        }
    }
</script>